import {Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core'
import {NgTemplateOutlet} from '@angular/common'
import {Router} from '@angular/router'

import {InsetsDirectiveModule} from '@ui/directives/wv/insets/insets-directive.module'

@Component({
  standalone: true,
  selector: 'shared-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  imports: [InsetsDirectiveModule, NgTemplateOutlet],
})
export class BottomSheetComponent {

  @Input() body!: TemplateRef<any>

  @Input() footer?: TemplateRef<any>

  @Output() backdropClicked = new EventEmitter<void>()

  @ViewChild('sheet') sheet: ElementRef<HTMLDivElement>

  hasBottomTabBar: boolean

  constructor(
    router: Router,
  ) {
    this.hasBottomTabBar = router.url === '/'
  }

  onClickBackdrop(e: MouseEvent): void {
    if (e) {
      const target = e.target as HTMLDivElement
      if (!target.classList.contains('backdrop')) return
    }

    this.backdropClicked.emit()
  }
}
