import {Injectable} from '@angular/core'
import {BsModalService} from 'ngx-bootstrap/modal'

import {DialogConfirmModel} from '@wv/models/dialog-confirm.model'
import {DialogConfirmComponent} from '@wv/components/dialog-confirm/dialog-confirm.component'

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
    private bsModalService: BsModalService,
  ) {
  }

  showConfirmDialog(options: DialogConfirmModel): void {
    this.bsModalService.show(DialogConfirmComponent, {
      initialState: {cfg: options},
      backdrop: 'static',
    })
  }

  showNativeDialogError(options: any): void {
  }
}
