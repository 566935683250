import {Component} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'

import {EduStreamType} from '@ui/schemes/ro/edu-stream'

import {EduState} from '@wv/states/edu.state'

@Component({
  templateUrl: './edu-type-picker.component.html',
  styleUrls: ['./edu-type-picker.component.scss'],
})
export class EduTypePickerComponent {

  types = [EduStreamType.Ordinary, EduStreamType.Independent]

  selected: EduStreamType

  onSelect: () => void

  constructor(
    private bsModalRef: BsModalRef,
    public eduState: EduState,
  ) {
    if (this.eduState.hasCourse) {
      this.selected = this.eduState.type
    }
  }

  save(): void {
    this.eduState.setType(this.selected)
    this.onSelect?.()
    this.bsModalRef.hide()
  }
}
