<div class="backdrop" (click)="onClickBackdrop($event)">

	<div class="sheet">

		@if (footer) {

			<div class="body">
				<ng-container [ngTemplateOutlet]="body"></ng-container>
			</div>

			<div class="footer" insets [paddingBottom]="20" [hasBottomTabBar]="hasBottomTabBar">
				<ng-container [ngTemplateOutlet]="footer"></ng-container>
			</div>

		} @else {
			<div class="body" insets [paddingBottom]="20" [hasBottomTabBar]="hasBottomTabBar">
				<ng-container [ngTemplateOutlet]="body"></ng-container>
			</div>
		}

	</div>

</div>
