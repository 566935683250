import {Component} from '@angular/core'
import {BsModalRef} from 'ngx-bootstrap/modal'

import {DialogConfirmModel} from '@wv/models/dialog-confirm.model'

@Component({
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent {

  cfg: DialogConfirmModel

  constructor(
    private bsModalRef: BsModalRef,
  ) {
  }

  hide(): void {
    this.bsModalRef.hide()
  }

  confirm(): void {
    this.cfg.onConfirm?.()
    this.hide()
  }
}
