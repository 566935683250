<div class="backdrop" insets [hasBottomTabBar]="true" [paddingTop]="120" [paddingBottom]="16">

	<div class="block">

		<div class="header" translate="common.edu_type_picker.title"></div>

		<div class="body">

			<div class="message">
				<i class="fal fa-info-circle text-primary"></i> {{ 'common.edu_type_picker.message' | translate }}
			</div>

			@for (type of types; track type) {

				<div class="item card" [class.selected]="selected === type" (click)="selected = type">

					<div class="item-text" translate="common.edu_type_picker.types.{{type}}"></div>

					<div class="radio-btn">
						<i class="ji jir-check"></i>
					</div>

				</div>
			}

		</div>

		<div class="footer">
			<div class="btn btn-primary" [class.disabled]="selected === undefined" translate="common.save" (click)="save()"></div>
		</div>

	</div>

</div>
