import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {BsModalService} from 'ngx-bootstrap/modal'
import {EMPTY, map, Observable, switchMap} from 'rxjs'
import moment from 'moment'

import {Lesson, LESSON_STATUS_FINISHED, LESSON_STATUS_NOT_STARTED, LESSON_STATUS_STARTED, LessonMaterialModel} from '@ui/schemes/ro/lesson'
import {EduService} from '@ui/services/edu.service'
import {EduStream, EduStreamType} from '@ui/schemes/ro/edu-stream'
import {List} from '@ui/schemes/ro/list'
import {Homework, HomeworkAnswer, HomeworkQuestion, HomeworkSolutionModel} from '@ui/schemes/ro/homework'
import {Module} from '@ui/schemes/ro/module'

import {DialogService} from '@wv/services/dialog.service'
import {EduTypePickerComponent} from '@wv/components/edu-type-picker/edu-type-picker.component'

@Injectable({
  providedIn: 'root',
})
export class EduState {

  private _streams: EduStream[]

  private _types: EduStreamType[]
  private _type: EduStreamType

  constructor(
    private router: Router,
    private eduService: EduService,
    private dialogService: DialogService,
    private bsModalService: BsModalService,
  ) {
  }

  initState(): Observable<void> {
    if (this.type) return EMPTY

    return this.eduService.listStreams().pipe(
      switchMap(streams => {
        if (!streams) return EMPTY

        this._streams = streams
        this._types = [...new Set(streams.map(s => s.type))]

        if (this.types.length === 1) {
          this.setType(this.types[0])
          return EMPTY
        }

        if (this.types.length > 1) {
          if (process.env.NG_APP_OVERRIDE_RN_SETTINGS === 'true') {
            console.log('%c[DEV] Automatically setting stream type as independent...', 'background: #00A9F4; color: white; font-size: x-large')
            this.setType(EduStreamType.Independent)
            return EMPTY
          }

          return new Observable<void>(observer => {
            this.showTypePicker(() => observer.complete())
          })
        }

        return EMPTY
      }),
    )
  }

  showTypePicker(onSelect?: () => void): void {
    this.bsModalService.show(EduTypePickerComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {onSelect},
    })
  }

  setType(type: EduStreamType): void {
    this._type = type
  }

  // requests

  getStreamId(subjectId: number): Observable<number> {
    return this.isOrdinCourse
      ? this.eduService.getOrdinSubjectStreamId(subjectId)
      : this.eduService.getIndepSubjectStreamId(subjectId)
  }

  getLang(subjectId: number): Observable<string> {
    return this.getStreamId(subjectId).pipe(
      map(streamId => {
        const stream = this.streams.find(s => s.id === streamId)
        return stream ? stream.language : null
      }),
    )
  }

  listTests(subjectId: number) {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listTests(streamId, subjectId)),
    )
  }

  getTest(subjectId: number, testId: number) {
    return this.listTests(subjectId).pipe(
      map(tests => tests.find(t => t.id === testId))
    )
  }

  startTest(subjectId: number, testId: number) {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.startTest(streamId, subjectId, testId)),
    )
  }

  listLessons(subjectId: number, params?: any): Observable<List<Lesson>> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listLessons(streamId, subjectId, params)),
    )
  }

  getLesson(subjectId: number, lessonId: number): Observable<Lesson> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.getLesson(streamId, subjectId, lessonId)),
    )
  }

  joinLesson(subjectId: number, lessonId: number): Observable<Lesson> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.joinLesson(streamId, subjectId, lessonId)),
    )
  }

  listModules(subjectId: number, lessonId: number): Observable<Module[]> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listModules(streamId, subjectId, lessonId)),
    )
  }

  listMaterials(subjectId: number, lessonId: number): Observable<LessonMaterialModel[]> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listMaterials(streamId, subjectId, lessonId)),
    )
  }

  getHw(subjectId: number, lessonId: number): Observable<Homework> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.getHw(streamId, subjectId, lessonId)),
    )
  }

  startHw(subjectId: number, lessonId: number): Observable<Homework> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.startHw(streamId, subjectId, lessonId)),
    )
  }

  finishHw(subjectId: number, lessonId: number): Observable<Homework> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.finishHw(streamId, subjectId, lessonId)),
    )
  }

  listHwQuestions(subjectId: number, lessonId: number): Observable<HomeworkQuestion[]> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listHwQuestions(streamId, subjectId, lessonId)),
    )
  }

  getHwQuestion(subjectId: number, lessonId: number, questionId: number): Observable<HomeworkQuestion> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.getHwQuestion(streamId, subjectId, lessonId, questionId)),
    )
  }

  listHwAnswers(subjectId: number, lessonId: number): Observable<HomeworkAnswer[]> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listHwAnswers(streamId, subjectId, lessonId)),
    )
  }

  sendHwAnswer(subjectId: number, lessonId: number, questionId: number, payload: any, params: any): Observable<HomeworkAnswer> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.sendHwAnswer(streamId, subjectId, lessonId, questionId, payload, params)),
    )
  }

  useHwSolution(subjectId: number, lessonId: number, questionId: number): Observable<HomeworkSolutionModel[]> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.useHwSolution(streamId, subjectId, lessonId, questionId)),
    )
  }

  listHwSolutions(subjectId: number, lessonId: number, questionId: number): Observable<HomeworkSolutionModel[]> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listHwSolutions(streamId, subjectId, lessonId, questionId)),
    )
  }

  listHwAllSolutions(subjectId: number, lessonId: number, questionId: number): Observable<HomeworkSolutionModel[]> {
    return this.getStreamId(subjectId).pipe(
      switchMap(streamId => this.eduService.listHwAllSolutions(streamId, subjectId, lessonId, questionId)),
    )
  }

  // navigation

  navigateOrdinLesson(lesson: Lesson): void {
    const subjectId = lesson.edustream_subject_id
    const lessonId = lesson.id

    const baseUrl = `/subjects/${subjectId}/lessons/${lessonId}`
    const navigateLesson = () => this.router.navigate([baseUrl])

    if (lesson.status === LESSON_STATUS_FINISHED) {
      navigateLesson()
      return
    }

    this.getLesson(subjectId, lessonId).subscribe({
      next: recent => {
        switch (recent.status) {
          case LESSON_STATUS_FINISHED: {
            navigateLesson()
            break
          }
          case LESSON_STATUS_STARTED: {
            if (recent.broadcast_url) {
              this.joinLesson(subjectId, lessonId).subscribe({
                next: () => this.router.navigate([`${baseUrl}/broadcasts`]),
              })
            } else {
              navigateLesson()
            }
            break
          }
          case LESSON_STATUS_NOT_STARTED: {
            this.dialogService.showConfirmDialog({
              title: 'common.lesson_card.not_started_modal.title',
              message: 'common.lesson_card.not_started_modal.message',
              messageParams: {date: moment(recent.date).format(`HH:mm`)},
            })
            break
          }
        }
      },
    })
  }

  navigateOrdinHomework(lesson: Lesson): void {
    const subjectId = lesson.edustream_subject_id
    const lessonId = lesson.id

    const available = l => l.available_homework_start_in <= 0
    const navigate = () => this.router.navigate([`/subjects/${subjectId}/lessons/${lessonId}/homework`])

    if (available(lesson)) {
      navigate()
      return
    }

    this.getLesson(subjectId, lessonId).subscribe({
      next: recent => {
        if (available(recent)) {
          navigate()
          return
        }

        this.dialogService.showConfirmDialog({
          title: 'common.homework_card.not_started_modal.title',
          message: 'common.homework_card.not_started_modal.message',
          messageParams: {date: moment(recent.available_homework_start).format(`HH:mm`)},
        })
      },
    })
  }

  // getters

  get streams(): EduStream[] {
    return this._streams
  }

  get types(): EduStreamType[] {
    return this._types
  }

  get type(): EduStreamType {
    return this._type
  }

  get hasCourse(): boolean {
    return this.type !== undefined
  }

  get hasCourses(): boolean {
    return this.types?.length > 1
  }

  get isOrdinCourse(): boolean {
    return this.type === EduStreamType.Ordinary
  }

  get isIndepCourse(): boolean {
    return this.type === EduStreamType.Independent
  }
}
