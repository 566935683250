@if (cfg) {

	<shared-bottom-sheet [body]="body"/>

	<ng-template #body>

		<div class="wrapper">

			@if (cfg.iconClass) {
				<div class="icon">
					<i class="{{cfg.iconClass}}"></i>
				</div>
			}

			<div class="title" [innerHTML]="cfg.title | translate : cfg.titleParams"></div>

			<div class="subtitle" [innerHTML]="cfg.message | translate : cfg.messageParams"></div>

			<button class="btn btn-primary" (click)="confirm()">{{ cfg.confirmText ? (cfg.confirmText | translate) : 'OK' }}</button>

			@if (cfg.cancelText) {
				<button class="btn btn-outline-primary" (click)="hide()">{{ cfg.cancelText | translate }}</button>
			}

		</div>

	</ng-template>

}
