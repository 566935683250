export interface EduStream {
  id: number;
  language: string;
  start_at: string;
  end_at: string;
  status: number
  name: string
  type: EduStreamType
}

export enum EduStreamType {
  Ordinary = 0,
  Independent = 1,
}

export interface EduStreamSubject {
  id: number;
  total_lessons: number;
  finished_lessons: number;
  access_end: string;
  edustream_subject: {
    id: number;
    teacher: number;
    edustream: number;
    subject_id: number;
    subject: {
      icon: string;
      id: number;
      name_ru: string;
      name_kk: string;
      modified_at: string;
    };
  }
}
